<template>
  <div class="history" :style="{'padding-bottom': screenshot ? '0' : '60px'}">
    <popup @close="posterActive = false" :src="poster" v-if="posterActive" guide="长按图片保存到相册"/>
    <popup v-if="appQrcode"
           src="https://img.xinshu.me/upload/7866e5d5d93246979b34a323fc496941"
           guide="长按图片识别小程序" @close="appQrcode = false"/>
    <template v-if="!loading">
      <template v-if="list.length > 0">
        <div class="head">
          <div class="img-box">
            <img src="@/assets/promotions/history/bg.svg" width="100%" height="49%">
          </div>
          <div class="date-box1">
            {{today.slice(0, 2)}} · {{today.slice(-2)}}
          </div>
          <div class="date-box2">
            我的历史今天
          </div>
          <div class="date-box3"></div>
        </div>
        <div class="list-wrap">
          <div class="control-bar">
            <b-link class="prev" :to="'/promotions/myhistory/' + switchDay.prev">
              <fa icon="angle-left"/>
              前一天
            </b-link>
            <b-link class="next" :to="'/promotions/myhistory/' + switchDay.next">后一天
              <fa icon="angle-right"/>
            </b-link>
          </div>
          <b-card class="mycard" v-for="item in list" :key="item.id">
            <div class="content-wrap">
              <div class="time">
                <img class="icon" :src="require('@/assets/promotions/history/'+item.source+'.svg')">
                <div class="year">{{Number(item.time) * 1000 | moment('YYYY')}}</div>
                <div class="day">{{Number(item.time) * 1000 | moment('MM.DD')}}</div>
              </div>
              <div class="content" @click="gotoInfo(item)">
                <div class="text">
                  <div class="text-i">{{item.text}}</div>
                </div>
                <div class="pic" v-if="item.pics.length > 0">
                  <square :src="item.pics[0].src" img-size="240"/>
                </div>
                <div class="more" v-if="item.pics.length > 1">{{item.pics.length}}</div>
              </div>
            </div>
          </b-card>
          <div v-if="screenshot" class="share-qrcode">
            <img src="https://img.xinshu.me/upload/06c84f692b5b417f923577b79c5963b8"
                 alt="">
          </div>
        </div>
      </template>
      <template v-else>
        <div class="head" v-if="!screenshot">
          <div class="img-box">
            <img src="@/assets/promotions/history/empty.svg" width="100%" height="49%">
          </div>
          <div class="tip">
            <span style="line-height: 1.9;">您的往年今日没有内容哦，</span>
            <b-link v-if="!wechat" class="text-primary pointer write"
                    to="/books?bookType=diarybook">为今天记一笔
            </b-link>
            <template v-else>
              <template v-if="canJumpApp">
                <wx-open-launch-weapp
                  id="launch-btn"
                  username="gh_2ab1fc18a64b"
                  path="pages/index/index.html"
                >
                  <script type="text/wxtag-template">
                  <style>.write {
                    color: #F76469;
                    font-size: 12px;
                    line-height: 1.2;
                    height: 20px;
                  }</style>
                  <div class="write">为今天记一笔</div>
                  </script>
                </wx-open-launch-weapp>
              </template>
              <template v-else>
                <div class="write" @click="showQrcode">为今天记一笔</div>
              </template>
            </template>
          </div>
        </div>
        <div class="list-wrap" v-if="headlines.length > 0">
          <div class="control-bar">
            <b-link class="prev" :to="'/promotions/myhistory/' + switchDay.prev">
              <fa icon="angle-left"/>
              前一天
            </b-link>
            <b-link class="next" :to="'/promotions/myhistory/' + switchDay.next">后一天
              <fa icon="angle-right"/>
            </b-link>
          </div>
          <div class="event-title">
            <div class="title-box">{{today.slice(0, 2) + '.' + today.slice(-2)}} 全球历史大事件</div>
            <div class="underline"></div>
          </div>
          <b-card class="mycard" v-for="(item, i) in headlines" :key="i">
            <div class="content-wrap">
              <div class="time">
                <div class="year">{{item.y}}</div>
                <div class="day">{{today.slice(0, 2) + '.' + today.slice(-2)}}</div>
              </div>
              <div class="content">
                <div class="text">
                  <div class="text-i" v-html="item.t"></div>
                </div>
                <div class="pic" v-if="item.pic">
                  <square :src="item.pic" img-size="480"/>
                </div>
              </div>
            </div>
          </b-card>
        </div>
        <div class="timeline">
          <ul>
            <li v-for="(item, i) in events" :key="i">
              {{item.y + '.' + today.slice(0, 2) + '.' + today.slice(-2) + '：'}}
              <span class="line-text" v-html="item.t"></span>
            </li>
          </ul>
          <div v-if="screenshot" class="share-qrcode">
            <img src="https://img.xinshu.me/upload/06c84f692b5b417f923577b79c5963b8"
                 alt="">
          </div>
        </div>
      </template>
      <div v-if="!screenshot" class="mybtn">
        <div class="btn1">
          <b-link v-if="!wechat" class="text-primary pointer write" to="/books?bookType=diarybook">
            为今天记一笔
          </b-link>
          <template v-else>
            <template v-if="canJumpApp">
              <wx-open-launch-weapp
                id="launch-btn"
                username="gh_2ab1fc18a64b"
                path="pages/index/index.html"
              >
                <script type="text/wxtag-template">
                <style>.write {
                  color: #F76469;
                  font-size: 14px;
                  line-height: 60px;
                  height: 60px;
                }</style>
                <div class="write">为今天记一笔</div>
                </script>
              </wx-open-launch-weapp>
            </template>
            <template v-else>
              <div class="write" @click="showQrcode">为今天记一笔</div>
            </template>
          </template>
        </div>
        <div class="btn2" @click="share">分享给好友</div>
      </div>
    </template>
    <div v-if="showInfo" class="history-info">
      <div class="info-wrap">
        <div class="info-head">
          <div class="info-time">
            <fa icon="calendar-check" fw/>
            {{$day(Number(historyInfo.time) * 1000).format('YYYY-MM-DD')}}
          </div>
          <div class="info-source">
            <img class="icon"
                 :src="require('@/assets/promotions/history/'+historyInfo.source+'.svg')">
          </div>
        </div>
        <hr size="1" style="color: #E8E8E8; border-style: dashed; width: 100%">
        <div class="info-content">
          <div class="info-text">{{historyInfo.text}}</div>
          <image-zone :value="historyInfo.pics"
                      v-if="historyInfo.pics && historyInfo.pics.length"
                      cols="3"/>
        </div>
      </div>
      <div class="mybtn backbtn" @click="showInfo = false">返回</div>
    </div>
  </div>
</template>

<script>
import ImageZone from '@/components/ImageZone.vue'
import { config } from '@/modules/wechat'

export default {
  name: 'myhistory',
  title: '我的历史今天',
  components: {
    ImageZone
  },
  data() {
    return {
      list: [],
      historyData: {},
      events: [],
      headlines: [],
      loading: true,
      screenshot: false,
      posterActive: false,
      poster: '',
      showInfo: false,
      historyInfo: {},
      appQrcode: false
    }
  },
  computed: {
    switchDay() {
      const date = this.$route.params.date || this.$day().format('MMDD')
      const month = ('0' + date.substr(0, 2)).slice(-2)
      const day = ('0' + date.slice(-2)).slice(-2)
      const str = '2020-' + month + '-' + day
      const dateTime = new Date(str)
      const prev = this.$day(dateTime).add(-1, 'day').format('MM-DD')
      const next = this.$day(dateTime).add(1, 'day').format('MM-DD')
      return {
        prev,
        next
      }
    },
    today() {
      const date = this.$route.params.date || this.$day().format('MMDD')
      const month = ('0' + date.substr(0, 2)).slice(-2)
      const day = ('0' + date.slice(-2)).slice(-2)
      return month + day
    },
    wechat() {
      return window.isWechat
    },
    canJumpApp() {
      const wx = navigator.userAgent.match(/MicroMessenger\/([\d.]+)/i)
      if (!wx) {
        return false
      }
      const v1 = wx[1]
      const compare = this.compareVersion(v1, '7.0.12')
      return compare >= 0
    }
  },
  watch: {
    $route: {
      handler() {
        this.screenshot = this.$route.query.screenshot === '1'
        this.init()
        if (this.wechat) {
          config()
        }
      },
      immediate: true
    }
  },
  methods: {
    async init() {
      try {
        this.list = await this.$req.get(`/api/mobile/status/today_in_history/${this.today}`, {
          silent: true
        })
      } finally {
        await this.getHistoryData()
        this.loading = false
      }
    },
    async getHistoryData() {
      this.events = []
      this.headlines = []
      this.historyData = await this.$req.get('/buckets/weixinshu:baiduhistory')
      const arr = this.historyData[this.today] || []
      for (let i = 0; i < arr.length; i++) {
        const ai = arr[i]
        if ('pic' in ai) {
          this.headlines.push(ai)
        }
      }
      this.events = arr
    },
    share() {
      const token = this.$store.state.token
      const url = `https://weixinshu.com/promotions/myhistory/${this.today}?token=${token}&screenshot=1`
      this.poster = 'https://canvas.xinshu.me/screenshot/?url=' + encodeURIComponent(url)
      this.posterActive = true
    },
    gotoInfo(item) {
      this.historyInfo = item
      this.showInfo = true
    },
    showQrcode() {
      this.appQrcode = true
    },
    compareVersion(v1, v2) {
      v1 = v1.split('.')
      v2 = v2.split('.')
      const len = Math.max(v1.length, v2.length)

      while (v1.length < len) {
        v1.push('0')
      }
      while (v2.length < len) {
        v2.push('0')
      }

      for (let i = 0; i < len; i++) {
        const num1 = parseInt(v1[i])
        const num2 = parseInt(v2[i])

        if (num1 > num2) {
          return 1
        } else if (num1 < num2) {
          return -1
        }
      }

      return 0
    }
  }
}
</script>

<style lang="scss">
[data-page="myhistory"] {
  main {
    padding-bottom: 0 !important;
  }
}
</style>

<style scoped lang="scss">
$boxWdith: 100px;
$mainColor: #F76469;
$bgColor: #F8F8F8;

.history {
  max-width: 560px;
  text-align: center;
  min-height: 100vh;
  background-color: $bgColor;
  padding: 0;
  position: relative;
  color: #fff;

  .head {
    color: $mainColor;
    width: 100%;
    position: relative;

    .img-box {
      padding-top: 50%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .date-box1 {
      font-size: 24px;
      position: absolute;
      top: 60px;
      left: 30px;
      width: $boxWdith;
      height: 40px;
      line-height: 40px;
      border: $mainColor 1px solid;
      background-color: $bgColor;
      z-index: 2;
    }

    .date-box2 {
      font-size: 12px;
      position: absolute;
      top: 100px;
      left: 30px;
      width: $boxWdith;
      height: 20px;
      line-height: 20px;
      border-color: $mainColor;
      border-style: none solid solid solid;
      border-width: 1px;
      background-color: $bgColor;
      z-index: 2;
    }

    .date-box3 {
      position: absolute;
      top: 65px;
      left: 35px;
      width: $boxWdith - 2px;
      height: 58px;
      border: $mainColor 1px solid;
      background-color: $bgColor;
      z-index: 1;
    }

    .tip {
      position: absolute;
      width: 100%;
      font-size: 12px;
      color: #000;
      bottom: 10%;
      text-align: center;

      .write {
        cursor: pointer;
        color: $mainColor;
      }
    }
  }

  .list-wrap {
    padding: 1rem;

    .control-bar {
      display: flex;
      justify-content: space-between;
      color: $mainColor;
      margin-bottom: 0.8rem;
    }

    .mycard {
      .card-body {
        padding: 1.25rem 1.25rem 1.25rem 0;
      }
    }

    .content-wrap {
      display: flex;
      height: 30%;

      .icon {
        position: absolute;
        width: 40%;
        left: 30%;
        top: calc(-1.25rem - 1px);
      }

      .time {
        position: relative;
        flex: 2;
        flex-direction: column;
        padding-top: 8%;
        color: #666666;

        .year {
          font-size: 16px;
        }

        .day {
          font-size: 12px;
        }
      }

      .time::after {
        content: "";
        width: 1px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        background: #EDEDED;
      }

      .content {
        flex: 7;
        display: flex;
        position: relative;
        cursor: pointer;

        .text {
          flex: 5;
          flex-direction: column;
          color: #949494;
          padding: 0 0.6rem;
          text-align: left;

          .text-i {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
          }
        }

        .pic {
          flex: 3;
          flex-direction: column;
        }

        .more {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 24px;
          height: 24px;
          line-height: 24px;
          background-color: rgba(27, 23, 22, .6);
          border-radius: 4px;
        }
      }
    }
  }

  .mybtn {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 560px;
    background-color: $mainColor;
    line-height: 60px;
    height: 60px;
    cursor: pointer;

    .btn1 {
      flex: 1;
      background-color: #fff;
      color: $mainColor;
    }

    .btn2 {
      flex: 1;
    }
  }

  .share-qrcode {
    text-align: end;
    width: 100%;
    max-width: 560px;
    height: 4rem;

    img {
      height: 100%;
    }
  }

  .timeline {
    color: #606060;
    padding: 1rem;
    text-align: left;

    ul {
      margin: 0px;
      padding-inline-start: 1.2rem;
    }

    li {
      margin-bottom: 1.4rem;
    }

    .line-text {
      color: #606060;
    }
  }

  .event-title {
    margin-bottom: 1rem;
    color: #fff;

    .title-box {
      font-size: 12px;
      width: 40%;
      height: 24px;
      line-height: 24px;
      background-color: $mainColor;
      border-radius: 8px 8px 0 0;
    }

    .underline {
      width: 100%;
      border-bottom: $mainColor 1px solid;
    }
  }

  .history-info {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-bottom: 50px;
    background-color: $bgColor;

    .info-wrap {
      padding: 1.25rem;
      height: 100%;
      overflow-y: auto;

      .info-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        color: #545454;

        .info-time {
          font-size: 14px;
          height: 100%;
          line-height: 50px;
        }

        .info-source {
          width: 26px;
          height: 26px;

          img {
            border-radius: 50%;
          }
        }
      }

      .info-content {

        .info-text {
          font-size: 16px;
          text-align: left;
          color: #8A8A8A;
          padding: 0.5rem 0 1rem 0;
        }
      }
    }

    .backbtn {
      display: block;
      color: $mainColor;
      background-color: $bgColor;
    }
  }
}
</style>
